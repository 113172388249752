import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Os nossos serviços | Os nossos serviços no Ink Haven Tattoo Studio
			</title>
			<meta name={"description"} content={"O Ink Haven Tattoo Studio dedica-se a fornecer uma gama de serviços adaptados à sua visão artística e estilo pessoal. "} />
			<meta property={"og:title"} content={"Os nossos serviços | Os nossos serviços no Ink Haven Tattoo Studio"} />
			<meta property={"og:description"} content={"O Ink Haven Tattoo Studio dedica-se a fornecer uma gama de serviços adaptados à sua visão artística e estilo pessoal. "} />
			<meta property={"og:image"} content={"https://airtotopstar.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://airtotopstar.com/img/5692224.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://airtotopstar.com/img/5692224.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://airtotopstar.com/img/5692224.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://airtotopstar.com/img/5692224.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://airtotopstar.com/img/5692224.png"} />
			<meta name={"msapplication-TileImage"} content={"https://airtotopstar.com/img/5692224.png"} />
		</Helmet>
		<Components.Header />
		<Section background="#c0c0c0" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h1" margin="12px 0" font="--headline2" md-font="--headline3">
							Uma tela de possibilidades
						</Text>
						<Text
							as="p"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							O Ink Haven Tattoo Studio dedica-se a fornecer uma gama de serviços adaptados à sua visão artística e estilo pessoal. Os nossos artistas qualificados estão aqui para o guiar em cada passo da sua viagem de tatuagem, desde o conceito inicial até à obra-prima final.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://airtotopstar.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://airtotopstar.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://airtotopstar.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Os nossos serviços de tatuagem
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Desenho de tatuagem personalizado
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Colabore com os nossos artistas para criar um desenho único que reflicta a sua individualidade. Quer se trate de uma peça simbólica, arte abstrata ou um retrato, damos vida à sua visão.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Cobertura e retoque
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Transforme uma tatuagem antiga numa nova obra de arte. Os nossos especialistas especializam-se em disfarces e retoques, oferecendo soluções criativas para atualizar ou alterar a sua tinta existente.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Tatuagens a cores e a preto e cinzento
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Escolha entre tatuagens de cores vibrantes ou a elegância intemporal do preto e cinzento. Os nossos artistas são excelentes em ambos, garantindo profundidade, contraste e clareza em cada peça.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Variedade de estilos
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Desde desenhos tradicionais, tribais e japoneses a realismo, geométricos e minimalistas - a nossa equipa diversificada pode atender a uma vasta gama de estilos de tatuagem.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Serviços de consulta
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Marque uma consulta individual para discutir as suas ideias, obter aconselhamento sobre a colocação e o dimensionamento e ficar a saber mais sobre o processo de assistência posterior.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Garantia de saúde e segurança
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Damos prioridade à sua segurança, cumprindo normas de saúde rigorosas, utilizando equipamento esterilizado e fornecendo instruções claras sobre cuidados posteriores.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});